@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
@import url(./index.css);
.logo {
  height: 50px;
}

.typingTitle{
  color: #fff;
}

.typingTitle p {
  color: var(--secondary-color);
  background: #ffcb00;
  display: inline-block;
  padding: 0 14px;
}

.applogo {
  height: 70px;
  margin: 40px 30px 10px 0;
}

header {
  padding: 10px 20px;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.heroContainerBackground {
  /*background: url(./assets/delivery.png);*/
  background: url(../public/bg.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: repeat-x;
  background-color: #00205d;
}

.heroContainer {
  max-width: 1600px;
  margin: 0 auto;
  padding: 140px 20px 100px 20px;
}

.headerContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: 0.5s;
  transition-timing-function: ease;
}

.headerContainer.sticky{
  background: rgba(255, 255, 255, 1);
  box-shadow: var(--shadow);
}

.navigation {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.navigation li {
  padding: 10px;
}

.headerContainer.sticky .navigation li a{
  color: var(--secondary-color);
}

.navigation li a {
  color: #fff;
  position: relative;
  font-size: 16px;
  top: 10px;
}

.navigation li a:before {
  content: "Coming Soon !";
  position: absolute;
  font-size: 10px;
  top: -17px;
  right: 0;
  opacity: 0.5;
}

.navigation li:first-child a:before {
  content: "Live";
  opacity: 1;
  background: var(--primary-color);
  color: #000000;
  padding: 0px 5px;
}

.navigation li:nth-child(4) a:before {
  content: "New";
  opacity: 1;
  background: var(--primary-color);
  color: #000000;
  display: inline-table;
  padding: 0px 5px;
}

.navigation li a:hover {
  color: var(--primary-color);
}

.logoSection {
  text-align: left;
  flex-wrap: wrap;
  align-content: center;
  display: flex;
  padding-top: 10px;
}

.home {
}

.heroSection {
  /* margin: 50px auto; */
  display: flex;
  justify-content: space-between;
}

.heroTitle {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-content: center;
  margin: 40px;
}

.heroRequrementContainer {
  background: white;
  padding: 30px;
  border-radius: 5px;
  box-shadow: var(--shadow);
  width: 100%;
  min-width: 400px;
}

.heroTitle h1 {
  font-size: 28px;
}

h4 {
  margin: 5px 0;
  font-size: 18px;
}

.packersForm {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex: 2;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.trustContainer {
  padding: 20px;
  background-color: #fff;
}

.trustContainer h2 {
  font-size: 40px;
  text-align: center;
}

.trustContainer {
  font-size: 40px;
  text-align: center;
}

/**************FOOTER SECTION *****************/

.footerContainer {
  background-color: var(--secondary-color);
}

.footerContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  padding: 0 0 50px 0;
}

.footer {
  color: #fff;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0px 20px;
}

.footer a {
  color: #fff;
}

.footer .copySection a {
  padding: 0 10px;
}

.footer .linkSection a {
  color: #ccc;
  font-size: 16px;
  font-weight: 100;
}

.footer a:hover {
  color: var(--primary-color);
}

.copySection {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  border-top: #666666 1px solid;
}

.linkSection ul li {
  display: block;
  font-size: 20px;
  font-weight: 500;
}

.footerLogo {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
}

.socialLinks{
  display: flex;
}

.socialLinks div{
  padding: 10px;
}

.socialLinks div a{
  font-size: 24px;
}

.footerDivision {
  display: flex;
}

.footerA {
  flex: 1;
}

.footerB {
  flex: 1;
  text-align: right;
}

.footerContent .linkSection ul li:first-child {
  padding-bottom: 10px;
}

.seoLinks {
  border-top: #666666 1px solid;
  padding-top: 30px;
}

.seolinkSection {
}

.seolinkSection ul li a {
  color: #ccc;
  font-size: 16px;
  font-weight: 100;
}

.seolinkSection h5 {
  margin-bottom: 14px;
  font-size: 18px;
}

.seolinkSection {
  margin-bottom: 30px;
}

.seolinkSection ul li::after {
  content: "|";
  margin: 0 20px;
  color: #ccc;
}

.linkSection {
  border-right: 1px solid #666;
}
/*********************Packers & Movers Requirement*********************/

.requirementPNM {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(0 0 0 / 40%);
}

.wrapper {
  background: white;
  max-width: 630px;
  border-radius: 5px;
  overflow: auto;
  max-height: 100vh;
  min-width: 630px;
}

.backdrop {
  filter: blur(4px);
}

.requirementHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 2px solid rgb(38 38 38 / 20%);
}

.requirementContent {
  padding: 30px;
}

.requirementFooter {
  padding: 20px;
  background: #f5f5f5;
}

/****************Date Selection ****************/

.dateSelectionContainer {
  padding: 0px 30px 30px 30px;
}

/*************Movement Summary ***************/

.movementSummaryContainer {
  padding: 30px;
}

.movementSummaryContainer table tr td:first-child {
  width: 75%;
}

.momentSummarytableContainer:first-child {
  margin-top: 0;
}

.momentSummarytableContainer {
  display: flex;
  margin-top: 20px;
}

.momentSummarytableContainer div:first-child {
  flex: 2;
}

.momentSummarytableContainer div:last-child {
  flex: 1;
}

/*************Payment Success ***************/

.PaymentSuccess {
  padding: 30px;
}

/*************Payment Success ***************/

.PaymentError {
  padding: 30px;
}

/******************Why ******************/

.how {
  max-width: 1600px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.how img {
  width: 100px;
}

.how h2 {
  margin-bottom: 70px;
  font-size: 30px;
  text-align: center;
  position: relative;
}

.howContainer {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.whiteBackground.HomeReviewSection {

}

.HomeReviewSection .howContainer {
  justify-content: space-evenly;
}

.HomeReviewSection .howContainer h3{
  color: #fab919;
  font-size: 28px;
}

.how h2::before {
  content: "";
  position: absolute;
  left: 40%;
  bottom: -20px;
  width: 300px;
  height: 3px;
  background: #fab919;
}

.whiteBackground {
  background-color: #fff;
  padding: 60px 0;
}
/**********************Why**************/

.why {
  display: flex;
}

.whyImage {
  background: url(../public/house-moving-concept.svg);
  width: 100%;
  flex: 1;
  height: 600px;
  background-size: cover;
}

.why div:nth-child(2) {
  flex: 1;
}

.whyUsContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whyUsContent ul li:before {
  content: "check";
  font-family: "Material Icons";
  color: #000000;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.whyUsContentWraper {
  padding: 30px;
}

.whyUsContentWraper ul li {
  display: block;
  margin: 10px auto;
}

.whyUsContentWraper h2 {
  margin-bottom: 50px;
  font-size: 30px;
  text-align: center;
  position: relative;
}

.whyUsContentWraper h2::before {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 300px;
  height: 3px;
  background: #fab919;
}

.commonContainer{
  max-width: 1600px;
  margin: 0 auto;
}

.commonWrapper{
  max-width: 1600px;
  margin: 0 auto;
}

.cookiePolicyWrapper{
  display: none; /* Cookie Policy is disabled*/
  position: fixed;
  bottom: 20px;
  margin: 0 auto;
  width: 100%;
  z-index: 10;
  padding: 10px;
}

.cookiePolicy {
  display: flex;
  background: #ffffff;
  margin: 0 auto;
  max-width: 1600px;
  padding: 20px;
  border-top: 5px solid #00205d;
  border-radius: 10px;
  box-shadow: var(--shadow);
}

.cookiePolicy .p{
  font-size: 0.9em;
    opacity: 0.5;
}

.cookiepolicyheading{
  font-weight: 800;
  font-size: 1.3em;
}

.cookieButtons{
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookieButtons .accept {
  background: #ffcb00;
}

.cookieButtons .reject{
  border: 1px solid #dcdcdc;
}

.cookieButtons button {
  background: none;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  margin: 10px;
  font-weight: 600;
  font-family: 'Poppins';
  padding: 0px 50px;
  height: 40px;
}