

.editorContainer::before{
    content: "";
    background-color: #00205d;
    height: 500px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.editorContainer{
    margin-top: 160px !important;
    background-color: #ffffff;
    max-width: 1600px;
    margin: 0 auto;
}

.editor{
    width: 1200px;
    margin: 0 auto;
    padding: 100px;
}

.editor h1{
    font-size: 2.5em;
    text-align: center;
}