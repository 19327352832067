.sitemapContainer{
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
    padding-top: 100px;
    padding-bottom: 50px;
}

.sitemapContainer a{
    color: #00205d;
}

.siteMapWrapper{
    background-color: #00205d;
}

.sitemap{
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 20px;
    background: #ffffff;
}

.sitemapHolder{
    background-color: #ffffff;
}

.sitemapHolder h1{
    text-align: center;
    padding: 20px;
    border-bottom: 1px solid #ffcb00;
}