@import url(./index.css);
.responsiveIdentifier{
  background-color: white;
  height: 5px;
  display: none;
}

.handBurgerIcon button{
  background: none;
  border: none;
}

.handBurgerIcon, .whatsAppIconResponsive{
  display: none;
}

.handBurgerIcon i, .whatsAppIconResponsive i{
  color: #ffffff;
  font-size: 24px;
  margin-top: 10px;
}

.headerContainer.sticky .handBurgerIcon i, .headerContainer.sticky .whatsAppIconResponsive i{
  color: #000000;
  font-size: 24px;
}

.navigation.responsiveNavigation::before{
  content: "";
  height: 100vh;
  width: 100vw;
  display: block;
  position: absolute;
  z-index: -1;
}

.navigation.responsiveNavigation ul li:after {
  font-family: 'Font Awesome 5 Free';
  content: "\f061";
  position: absolute;
  right: 20px;
  top: 25px;
  color: #ffffff;
  font-weight: 600;
}

.navigation.responsiveNavigation ul li:last-child::after {
  content: "";
}

.headerContainer.sticky .navigation.responsiveNavigation li a{
  color: #ffffff;
}

.responsiveMenuClose{
  color: #ffffff;
  background: none;
  border: none;
  font-size: 24px;
  margin: 30px 0 0 20px;
}

.navigation.responsiveNavigation {
  display: none;
  position: absolute;
  background: #2e2e2e;
  left: 0;
  top: 0;
  height: 100vh;
  min-width: 300px;
  z-index: 1;
}

.navigation.responsiveNavigation ul li{
  position: relative;
}

.navigation.responsiveNavigation ul {
  width: 100%;
  margin-top: 10px;
}

.navigation.responsiveNavigation li:first-child a:before{
  display: inline-table;
}

.navigation.responsiveNavigation li:nth-child(5) a:before{
  display: inline-table;
  content: "Live";
  opacity: 1;
  color: #000000;
  padding: 0 5px;
  background-color: var(--primary-color);
}

.navigation.responsiveNavigation ul li {
  display: block;
  padding: 20px;
  width: auto;
  border-bottom: 1px solid #746c4c;
}

.responsiveNavigation.navigation li a{
  display: block;
  width: 100%;
}

.responsiveNavigation.navigation li a:before{
  left: 0;
}

@media only screen and (max-width: 767px) {
  .responsiveIdentifier{
    background-color: red;
  }


  h1{
    font-size: 28px !important;
  }

  h2{
    font-size: 26px !important;
  }

  h3{
    font-size: 24px !important;
  }

  p{
    font-size: 18px !important;
  }

  header {
    justify-content: center;
    width: 100%;
  }
  .heroContainerBackground{
    background: #00205d;
  }
  .navigation {
    display: none;
  }

  .handBurgerIcon, .whatsAppIconResponsive{
    display: block;
  }

  .packersForm.new{
    /* display: block; */
  }

  .heroTitle.new{
    margin: 0 !important;
  }

  .quotesBlock{
    grid-template-columns: 1fr !important;
  }

  .packersmoverscontainer{
    padding-right: 0 !important;
  }

  .box{
    text-align: center;
  }

  .heroTitle {
    margin: 10px;
  }
  .heroSection {
    margin: 0;
  }
  .heroContainer {
    padding-top: 90px;
  }
  .footerA {
    display: none;
  }
  .footerB {
    text-align: left;
    padding-bottom: 20px;
  }
  .footerLogo {
    padding: 20px 0;
    display: block;
    text-align: center;
  }

  .socialLinks{
    justify-content: space-evenly;
  }

  .copySection {
    font-size: 12px;
  }

  .headerContainer{
    display: flex;
  }

  .wrapper {
    min-width: auto;
    border-radius: 0px;
  }

  .requirementPNM {
    display: block;
    align-items: stretch;
  }

  .requirementContent {
    padding: 10px;
  }

  .floorDivision {
    display: block !important;
  }

  .floorDivision div {
    justify-content: left !important;
  }

  .requirementHeaderTitle {
    width: 80vw;
  }

  .requirementHeaderTitle h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dateOptionHolder {
    display: block !important;
  }

  .dateOptions {
    display: block;
    width: 100%;
  }

  .dateOptions button {
    width: 100%;
    margin: 10px;
  }

  .react-calendar__viewContainer .react-calendar__month-view:last-child {
    display: none;
  }

  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 100% !important;
  }

  .momentSummarytableContainer {
    display: block;
  }

  .momentSummarytableContainer div:last-child {
    margin-top: 10px;
  }

  .howContainer {
    flex-direction: column;
  }

  .whyImage {
    display: none;
  }

  .how h2::before {
    left: auto;
  }

  .whyUsContentWraper h2::before {
    left: 0;
  }

  .whiteBackground {
    padding: 10px 0;
  }

  .howContainer div {
    padding-bottom: 30px;
  }

  .seolinkSection div {
    display: none;
  }


  .quoteIcon{
    float: none !important;
  }

  .copySection div:last-child{
    text-align: right;
  }
  
  .ourServicesWrapper{
    grid-template-columns: 1fr !important;
  }

  .aboutSectionWrapper, .whyCustomerChooseUsWrapper{
    flex-direction: column-reverse;
  }

  .whyCustomerChooseUsWrapper{
    flex-direction: column;
  }

  .heroSection.new{
    flex-direction: column-reverse;
  }

  .contactSectionBlock{
    flex-direction: column;
  }

  .contactSectionBlock .contactContainer{
    margin-top: 20px;
  }

  .contactContainer{
    flex-direction: column;
  }

  .logoSection {
    width: 100%;
    justify-content: space-between;
  }

  .heroRequrementContainer{
    min-width: auto;
    padding: 10px;
  }

  .blogItems{
    grid-template-columns: 1fr !important;
  }

  .packersmoverscontainer.mobileHeading{
    display: block !important;
  }

  .packersmoverscontainer.desktop h1, .packersmoverscontainer.desktop p{
    display: none;
  }

  .howitworkscontainer{
    grid-template-columns: 1fr 1fr !important;
  }

  .sitemap{
    grid-template-columns: 1fr 1fr !important;
  }

  .pricingcontainer{
    grid-template-columns: 1fr !important;
  }

  .contactBlock{
    border: 1px solid #ffd960; 
    background: #ffd9601f;
    padding: 20px 30px !important;
    margin-left: 0px;
  }

  .contactBlock{
    margin-left: 0 !important;
  }

  .whyUsStorage{
    flex-direction: column;
  }

  .storageCategoryWrapper .storageCategory{
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 10px !important;
    grid-row-gap: 10px !important;
  }

  .storageCategoryWrapper{
    padding: 10px !important;
  }

  .storageCategoryWrapper .storageCategory .storageCategoryItem{
    padding: 10px !important;
    font-size: 14px;
  }
  

  .whyUsStorage .whyUsStorageIcons div:nth-child(2):after {
    border-right: none;
  }

  .storageForm{
    flex-direction: column;
  }

  .storageWorks .storageWorksIllustration {
    grid-template-columns: 1fr !important;
  }

  .whyUsStorage, .whyUsStorageIcons{
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }

  .whyUsStorage div, .whyUsStorageIcons div{
    width: auto !important;
  }

  .whyUsStorage div:after, .whyUsStorageIcons div:after{
    content: none !important;
  }

  .whyUsStorageIcons div:last-child{
    display: none;
  }

  .storageSelection{
    grid-template-columns: 1fr !important;
  }
  
  .leadModelWrapper .storageFormData .textFielddiv{
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .leadModelWrapper .storageFormData{
    grid-template-columns: 1fr !important;
  }

  .leadModelWrapper .leadModel{
    border-radius: 0 !important;
  }

  .leadModelWrapper{
    align-items: flex-start !important;
  }

}


 

/* Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 991px) and (min-width: 767px){ 
  .responsiveIdentifier{
    background-color: orange;
  }

  .quoteIcon{
    float: none !important;
  }

  .ourServicesWrapper{
    grid-template-columns: 1fr 1fr !important;
  }

  .aboutSectionWrapper, .whyCustomerChooseUsWrapper{
    flex-direction: column-reverse;
  }

  .whyCustomerChooseUsWrapper{
    flex-direction: column;
  }

  .heroSection.new{
    flex-direction: column-reverse !important;
  }

  .bodyContainer .packersForm{
    display: none !important;
  }
  .contentContainer{
    
  }
  
  .faqWrapper{
    padding: 1.5em !important;
  }

  .contactSectionBlock{
    flex-direction: column;
  }

  .contactSectionBlock .contactContainer{
    margin-top: 20px;
  }
  
  .blogItems{
    grid-template-columns: 1fr 1fr !important;
  }

  .packersForm.new{
    display: flex !important;
  }

  .packersmoverscontainer.mobileHeading{
    display: block !important;
  }

  .packersmoverscontainer.desktop h1, .packersmoverscontainer.desktop p, .packersmoverscontainer.desktop hr{
    display: none;
  }


  .packersmoverscontainer{
    padding-right: 0 !important;
  }

  .navigation ul li{
    display: none;
  }

  .logoSection {
    width: 100%;
    justify-content: space-between;
  } 
  
  .handBurgerIcon, .whatsAppIconResponsive{
    display: block;
  }

  .heroContainer {
    padding-top: 90px;
  }

  .howitworkscontainer{
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .sitemap{
    grid-template-columns: 1fr 1fr !important;
  }

  .pricingcontainer{
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .whyUsStorage{
    flex-direction: column;
  }

 }













/* Large devices (desktops, less than 1200px) */
@media only screen and (max-width: 1200px) and (min-width: 992px){ 
  .responsiveIdentifier{
    background-color: yellow;
  }
  .quoteIcon {
    float: none !important;
  }
  
  .blogItems{
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .howitworkscontainer{
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .sitemap{
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .pricingcontainer{
    grid-template-columns: 1fr 1fr 1fr !important;
  }
 }

/* X-Large devices (large desktops, less than 1400px */
@media only screen and (max-width: 1399px)  and (min-width: 1200px) { 
  .responsiveIdentifier{
    background-color: lightgreen;
  }
 }

/* XX-Large devices (larger desktops, 1400px and up)*/
@media only screen  and (min-width: 1400px){ 
  .responsiveIdentifier{
    background-color: green;
  }
 }