.packersmoverscontainer.mobileHeading{
    width: 100%;
    margin-top: 40px;
    display: none;
}

.contentContainer{
    margin: 2em;
    width: 100%;
    text-align: center;
    line-height: 2em;
}

.reviewContent{
    text-align: center;
}

.reviewQuote{
    position: relative;
    padding: 20px;
    height: 115px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 50px;
}

.reviewerName{
    font-size: 20px;
    margin-top: 10px;
    font-weight: 800;
  }

  .reviewerName a{
    color: #000000;
  }

.reviewQuote::before{
    font-family: 'Font Awesome 5 Free';
    content: "\f10d";
    position: absolute;
    left: 0;
    top: 0;
    color: #ccc;
    font-size: 2em;
    font-weight: 600;
}

.reviewQuote::after{
    font-family: 'Font Awesome 5 Free';
    content: "\f10e";
    position: absolute;
    bottom: 0;
    right: 0;
    color: #ccc;
    font-size: 2em;
    font-weight: 600;
}

.howitworkscontainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 20px;
    margin-bottom: 20px;
}

.howitworksWrapper{
    max-width: 1600px;
    margin: 0 auto;
    background: #ffffff;
    border-top: 4px solid #ffcb00;
    border-bottom: 4px solid #ffcb00;
    padding: 2em 0px;
}

.howitworksitem img {
    width: 100%;
}

.howitworksWrapper h2{
    text-align: center;
    padding: 20px;
    font-size: 34px;
}

.howitworksitem {
    border: 1px solid #ededed;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
    cursor: default;
    position: relative;
}

.howitworksitem:before {
    position: absolute;
    left: 0;
    top: 0;
    font-family: sans-serif;
    font-size: 50px;
    padding: 10px 20px;
    color: #ffcb00;
    opacity: 0.5;
}

.howitworksitem:nth-child(1)::before {
    content: "1";
}

.howitworksitem:nth-child(2)::before {
    content: "2";
}

.howitworksitem:nth-child(3)::before {
    content: "3";
}

.howitworksitem:nth-child(4)::before {
    content: "4";
}

.howitworksitem:nth-child(5)::before {
    content: "5";
}

.howitworksitem:hover{
    background-color: #ffcb0029;
    border: 1px solid #ffcb0029;
}

.howitWorksContent{
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    line-height: 2em;
    padding: 0 20px;
    margin-bottom: 20px;
}

.howitworksitem p {
    font-size: 14px;
    font-weight: 100;
    margin: 5px 0;
}

.howitworksitem h3 {
    border-top: 1px solid #ededed;
    padding-top: 15px;
}
  
.howitWorksContent:hover h3{
    background-color: red !important;
}

.youtubeVideo{
    border: none;
    min-height: 450px;
}

.galleryWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 100px 0px;
    margin-bottom: 20px;
    max-width: 1600px;
    margin: 0 auto;
    display: none;
}

.galleryDiv{
    display: flex;
}

.galleryImage img{
    width: 100%;
}

.galleryImage{
    
    display: flex;
    justify-content: center;
}

.galleryDiv:nth-child(odd){
    padding-top: 50px;
}

.galleryDiv:nth-child(even){
    padding-bottom: 50px;
}











.pricingcontainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding: 20px;
    margin-bottom: 20px;
}

.pricingWrapper{
    max-width: 1600px;
    margin: 0 auto;
    background: #ffffff;
    border-top: 4px solid #ffcb00;
    padding: 2em 0px;
}

.pricingitem img {
    width: 100%;
}

.pricingWrapper .pricingitem h2{
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
}

.pricingWrapper .pricingitem h2:before {
    font-family: 'Font Awesome 5 Free';
    content: "\e1bc";
    position: absolute;
    top: 25px;
    left: 0;
    font-size: 0.6em;
    font-weight: 600;
}

.pricingWrapper .pricingitem h2:after {
    content: "*";
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 0.5em;
    font-weight: 600;
}

.pricingWrapper .pricingitem:last-child h2:after {
    content: "";
}

.pricingWrapper .pricingitem:last-child h2:before {
    content: "";
}

.pricingWrapper h2{
    text-align: center;
    padding: 20px;
    font-size: 34px;
    display: block;
}

.pricingitem {
    border: 1px solid #ededed;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
    cursor: default;
    position: relative;
}


.pricingitem:nth-child(odd){
    background-color: #ffcb000f;
    border: 1px solid #ffcb0029;
}

.pricingContent{
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    line-height: 2em;
    padding: 0 20px;
    margin-bottom: 20px;
}

.pricingitem p {
    font-size: 14px;
    font-weight: 100;
    margin: 5px 0;
    text-align: left;
}

.pricingitem h3 {
    border-bottom: 1px solid #ededed;
    padding-bottom: 15px;
}
  
.pricingContent:hover h3{
    background-color: red !important;
}

.pricingitem p:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f00c";
    font-size: 1em;
    font-weight: 600;
    margin-right: 10px;
}

.pricingitem:last-child p:before {
    content: "";
}

.pricingitem:last-child p {
    padding: 0px 20px;
    text-align: center;
}

.pricingitem div{
    display: inline-block;
    margin-bottom: 16px;
}