.storageWrapper{
    h1{
        font-size: 2.5em;
        max-width: 800px;
        padding: 10px;
    }


    h2{
        font-size: 2em;
        font-weight: 100;
        padding: 20px;
        color: #ffcb00;
    }

    .storageMain{
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: #00205da3;
        color: #ffffff;

        p{
            padding: 20px;
            font-size: 1.2em;
            font-weight: 100;
        }
    }
}

.storageCategoryWrapper{

    background-color: #fff;
    text-align: center;
    padding: 40px;


    .storageCategory{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        background-color: #fff;
        padding-top: 30px;

        .storageCategoryIcon{
            font-size: 4em;
        }

        .storageCategoryItem{
            text-align: center;
            padding: 40px;
            border-radius: 10px;
            background-color: rgb(0 32 93 / 4%);
            border: 1px solid rgb(0 32 93 / 8%);
            color: #363a43;
        }
    }

    

}

.dividerText{
    background: #262626;

    h2{
        text-align: center;
        color: #fff;
        padding: 50px;
        font-size: 2em;
        padding: 35px;
    }

    .highlight{
        color: #ffcb00;
    }
}

.storageForm{
    background: #ffffff;
    border-radius: 5px;
    padding: 20px 30px;
    display: inline-flex;
    align-items: center;

    div{
        width: 100%;
    }

}

.selectYourStorageCity{
    width: 300px !important;
}

.whyUsStorageWrapper{
    background: #00205d;
    color: #ffffff;

}

.whyUsStorage, .whyUsStorageIcons{

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    
        display: flex;

        svg{
            display: inline-block;
            font-size: 2.5em;
        }
    
        p{
            display: inline-block;
            font-size: 1.1em;
            font-weight: 600;
            margin-left: 20px;
            display: block;
            width: 100%;
        }

        div{
            width: 180px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            padding: 1em;
            position: relative;
        }

        div:after {
            border-right: 1px solid;
            content: "";
            height: 40px;
            width: 10px;
            right: 0;
        }

        div:last-child:after {
            border-right: 0px solid;
        }
    

}

.storageWorks{

    h2{
        font-size: 2em;
        text-align: center;
        padding: 20px;
        margin-top: 30px;
    }

    .storageWorksIllustration{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 20px;

        div img{
            width: 100px;
            margin-bottom: 20px;
        }
    }

    h3{
        padding: 10px;
    }

    div{
        text-align: center;
        padding: 30px;
    }
}

.leadModelWrapper{

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: auto;

    .leadModel{
        background: #ffffff;
        margin: 0 auto;
        width: 600px;
        border-radius: 10px;
        padding: 20px;
        overflow: auto;
        
        h4 {
            margin: 0 0 5px 0;
            font-size: 1.5em;
        }

        .closeButton{
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }
    }

    .storagePricing{
        margin-bottom: 20px;
    }

    .packersTitle {
        font-size: 14px;
        padding: 20px 0 10px 0;
        text-align: center;
    }

    .nomsClass{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .storageFormData{
        display: grid;
        grid-template-columns: 1fr 1fr;

        .textFielddiv:nth-child(odd){
            padding-right: 10px;
            padding-bottom: 20px;
        }
        .textFielddiv:nth-child(even){
            padding-left: 10px;
            padding-bottom: 20px;
        }
    }
}

.storageSelection{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 10px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.storageSelection .storageLableContentHolder {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.storageLableContentHolder:hover, .storageLableContentHolder.active{
    background: #ffcb0026;
    border: 1px solid #ffcb00;
}

.storageSelection .storageLabelsforSelection {
    display: flex;
    flex-direction: column;
}

.storageSelection .storageLabelsforSelection p{
    font-weight: 600;
}

.storageSelection .storageLabelsforSelection span{
    font-size: 12px;
}

.divider.storage{
    margin: 20px 0;
    background-color: #cccccc;
}

.storageIconsSelection {
    padding: 10px 15px 10px 5px;
}