.pageNotFound{
    text-align: center;
    padding-top:80px;
}

.pageNotFound img{
    width: 30%;
}

.quoteIcon{
    width: 100px;
    float: left;
    margin-right: 10px;
}

.subTitle{
    font-size: 1.25em;
    font-weight: 100;
    margin-top: 5px;
}

.divider{
    border: none;
    height: 1px;
    background-color: #ffcb00;
    margin: 30px 0;
}

.packersmoverscontainer{
    color: #fff;
    padding-right: 25px;
    border-radius: 5px;
    width: 100%;
}

.packersForm.new{
    align-content: baseline;
    justify-content: left;
    padding: 40px 0;
}

.quotesBlock{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.box{
    background-color:#ffffff12;
    padding:20px;
    border-radius:10px;
    color:#fff;
    /* display:flex;
    align-items:center;
    justify-content:center;
    font-family:sans-serif; */
  }

  .box h2{
    font-size: 22px;
  }

  .box p{
    font-size: 16px;
    font-weight: 100;
  }

p.description{
    font-weight: 100;
    font-size: 14px;
    text-align: justify;
    margin-top: 10px;
    display: none;
  }

  .heroSection.new{
    align-items: center;
    /* margin: 0 20px; */
  }

  .heroTitle.new{
    margin: 10px;
  }

  .heroContainerBackground.new{
    background: url(../../public/packers-bg.png);
    background-color: #00205d;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
  }

  .contactSectionBlock{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contactContainer{
    display: flex;
  }

  .contactBlock{
    padding: 0 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: 20px;
  }

  .contactBlock i{
    margin-right: 30px;
    font-size: 30px;
    display: flex;
  }

  
  .contactBlock .details{
    font-weight: bold;
    font-size: 1.25rem;
  }

  .FAQlable h2{
    font-size: 34px;
    text-align: center;
    margin-bottom: 20px;
  }

  .faqWrapper{
    max-width: 1600px;
    margin: 0 auto;
  }

  .sectionWrapper{
    padding: 40px;
    background: #ffffff;
  }

  a.link{
    color: #000000 !important;
    padding: 0 30px;
    display: flex;
    align-items: center;
  }

  .accordionContainer {
    overflow: hidden;
    max-width: 1000px;
    margin: 0 auto;
}

.trustBadge{
    color: #ffffff;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}

.trustBadge i{
    margin-right: 10px;
}

.ourServicesWrapper{
    max-width: 1600px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
}

.aboutSectionWrapper, .whyCustomerChooseUsWrapper {
    display: flex;
    border-bottom: 4px solid #ffcb00;
    max-width: 1600px;
    margin: 0 auto;
}

.aboutSectionWrapper img, .whyCustomerChooseUsWrapper img{
    width: 100%;
    height: auto;
    margin: 20px 0px;
}

.aboutSectionImage, .whyCustomerChooseUsImage{
    flex: 1;
    display: flex;
    align-items: center;
    background: #ffffff;
}

.whyCustomerChooseUsImage img{
    position: relative;
}

.aboutSectionContent, .whyCustomerChooseUsContent{
    flex: 1;
    background: #ffffff;
    display: flex;
    align-items: center;
}


.contentContainer h2{
   margin-bottom: 20px;
   font-size: 34px;
}

.serviceImage{
    border-radius: 5px;
    width: 350px;
    height: 150px;
}

.services{
    background: #ffffff;
    height: 150px;
    width: 100%;
    margin: 15px 15px 15px 0px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    /* box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.05), 0 1px 4px hsla(0, 0%, 0%, 0.05), 0 2px 8px hsla(0, 0%, 0%, 0.05);; */
}

.serviceHolder{
    display: flex;
    align-items: end;
    margin-bottom: 20px;
    margin-right: 20px;
}

.servicesContent{
    display: none;
    padding: 0 10px;
}

.serviceWrapper{
    padding: 0px 20px;
}



.image1{
    background-image: url(https://i.pinimg.com/564x/1f/ad/36/1fad36f81d1a98826db410c6bdef973c.jpg);
    background-position: 0 155px;
}

.image2{
    background: url(https://i.pinimg.com/564x/bc/8e/4b/bc8e4b260bdc654ffae36d21e6289e16.jpg);
    background-position: 0 330px;
}

.image3{
    background: url(https://i.pinimg.com/564x/21/64/41/216441fc82ccb9fea7e2f823c9284f46.jpg);
    background-position: 0 240px;
}

.image4{
    background: url(https://i.pinimg.com/564x/f4/cf/58/f4cf58b180b014e653a29ffa3aafb218.jpg);
    background-position: 0 230px;
    margin-right: 0;
}

.services{
    background-size: 100%;
    cursor: pointer;
    background-position: center;
}

.requirementHeading{
    font-size: 32px;
}

.services::before {
    content: "";
    background: #ffffff;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    opacity: 0.3;
}

.services:hover::before {
    opacity: 0;
}