.react-calendar,
button.react-calendar__tile,
button.react-calendar__navigation__label {
  font-family: "Poppins", sans-serif !important;
}

.react-calendar.react-calendar--doubleView {
  border: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  background: #fff;
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend:disabled {
  color: #ccc;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  padding: 10px !important;
  height: 41px;
  border-radius: 40px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

button.react-calendar__navigation__label {
  font-size: 16px;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  font-size: 25px;
}

button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  background: #ffcb00;
  color: #262626;
}
