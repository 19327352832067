:root {
  --primary-color: #ffcb00;
  --secondary-color: #262626;
  --background-color: rgb(247 250 255);
  --container-width: "1600px";
  --shadow: 0 1px 2px hsla(0, 0%, 0%, 0.05), 0 1px 4px hsla(0, 0%, 0%, 0.05),
    0 2px 8px hsla(0, 0%, 0%, 0.05);
}

body {
  background: var(--background-color);
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: var(--secondary-color);
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: inline-block;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

p {
  margin: 0;
  padding: 0;
}
