.blogHeadContainer{
    margin-top: 150px;
    text-align: center;
    color: #ffffff;
}

.blogMainPage{
    margin: 100px 10px;
}

.blogHeadContainer::before{
    content: "";
    background-color: #00205d;
    height: 500px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.blogItems{
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.item .image{
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0px 0px;
    background-size: cover;
    background-position: center;
}

a.articleLink{
    color: var(--secondary-color);
}

.blogItems .item h2::after{
    content: "\f062";
    font-family: 'Font Awesome 5 Free';
    transform: rotate(45deg);
    position: absolute;
    right: 0;
    top: 0;
}

.contentWrapper{
    padding: 12px;
    border-radius: 0px 0px 10px 10px;
}

.articleSummary{
    text-align: justify;
    font-weight: 100;
}

.contentWrapper h2 {
    font-size: 20px;
    position: relative;
    padding-right: 20px;
}

.blogItems .item {
    box-shadow: 0 0px 2px 0 rgb(32 33 36 / 28%);
    border-radius: 10px;
    padding: 10px;
    background-color: #ffffff;
}

.articalDate{
    font-size: 14px;
}

.articleSchedule {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: #ffcb00;
    display: none;
}

.articalDate::before {
    content: "\f111";
    font-family: 'FontAwesome';
    font-size: 5px;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    top: -2px;
}















.articlePage::before{
    content: "";
    height: 100%;
    width: 100%;
    position: fixed;
    background-image:url(../../public/articlebg.jpg) ;
    background-position: center;
    background-size: cover;
    z-index: -1;
    top: 0;
    left: 0;
}

.articlePage::after {
    background: #00205d;
    position: fixed;
    top: 0;
    left: 0;
    content: "";
    height: 100%;
    width: 100%;
    filter: opacity(0.9);
    z-index: -1;
}

.articleContainer {
    max-width: 1000px;
    padding: 50px;
    background: #ffffff;
    margin: 250px auto 125px auto;
    border-bottom: 10px solid #ffcb00;
}

.articleHeading{
    text-align: center;
}

article{
    padding-top: 30px;
    line-height: 2;
    text-align: justify;
}

.articleHeader{
    text-align: center;
}

.articleHeader p{
    margin: 20px 0;
}

.blogHRlink{
    background: #ffcb00;
    height: 2px;
    border: none;
    width: 25%;
    margin-top: 30px;
}