.privacyPolicyContainer{
    max-width: 1600px;
    margin: 0 auto;
    margin-top: 120px;
}

.privacyPolicyContainer h1, .privacyPolicyContainer h2, .privacyPolicyContainer h3, .privacyPolicyContainerh4, .privacyPolicyContainerh5, .privacyPolicyContainerh6, .privacyPolicyContainerli, .privacyPolicyContainerp {
    margin: 0 0 16px
}

 .privacyPolicyContainer h1 {
    font-size: 40px;
    line-height: 60px
}

 .privacyPolicyContainer h1, .privacyPolicyContainer h2 {
    font-weight: 700
}

 .privacyPolicyContainer h2 {
    font-size: 32px;
    line-height: 48px
}

 .privacyPolicyContainer h3 {
    font-size: 24px;
    line-height: 36px
}

 .privacyPolicyContainer h3, .privacyPolicyContainer h4 {
    font-weight: 700
}

 .privacyPolicyContainer h4 {
    font-size: 20px;
    line-height: 30px
}

 .privacyPolicyContainer h5, .privacyPolicyContainer h6 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700
}

 .privacyPolicyContainer a {
    text-decoration: none;
    cursor: pointer;
    color: #000
}

 .privacyPolicyContainer a:hover,a[rel~=nofollow] {
    text-decoration: underline
}

 .privacyPolicyContainer a[rel~=nofollow] {
    color: #553df4
}

 .privacyPolicyContainer a[rel~=nofollow]:hover {
    text-decoration: none
}

 .privacyPolicyContainer .visible {
    display: block
}

 .privacyPolicyContainer .hidden {
    display: none
}

 .privacyPolicyContainer .page {
    width: 100%
}

 .privacyPolicyContainer .container {
    position: relative;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 70px;
    background: #ffffff;
}

 .privacyPolicyContainer .header {
    padding: 16px 0
}

 .privacyPolicyContainer .header .title {
    font-size: 28px;
    line-height: 42px;
    font-weight: 700
}

 .privacyPolicyContainer .translations-list-container {
    padding-bottom: 8px;
    margin: 0 0 16px
}

 .privacyPolicyContainer .translations-list-container .translations-list {
    margin: 0;
    padding: 0;
    list-style: none
}

 .privacyPolicyContainer .translations-list-container .translations-list .translations-list-item {
    display: inline-block;
    padding: 0;
    margin: 0 8px 8px 0;
    font-weight: 700;
    color: #553df4
}

 .privacyPolicyContainer .translations-list-container .translations-list .translations-list-item a {
    display: inline-block;
    color: #553df4;
    border: 1px solid #553df4;
    border-radius: 32px;
    padding: 4px 16px
}

 .privacyPolicyContainer .translations-content-container {
    padding-top: 16px;
    word-break: break-all
}

.privacyPolicyContainer::after{
    content: "";
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00205d;
}